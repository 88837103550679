import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import RelativeWrapper from '../../components/layout/RelativeWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';
import FeatureRow from '../../components/section/FeatureRow';
import ClientLogoBar, {ClientLogos} from '../../components/section/ClientLogoBar';
import CaseStudyCarousel from '../../components/section/CaseStudyCarousel';

import CheckmarkTextList from '../../components/frills/CheckmarkTextList';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: left calc(40% - 550px) top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 170px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
    justify-content: flex-end;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
};

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="Rota Software for Leisure Staff | Rotaready"
      description="Whether you run a gym, golf club or museum, let our rota software for leisure staff take the stress out of planning shifts & tracking attendance"
      url="leisure"
    />
    <DarkWrapper
      backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
      backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="Leisure" />
              <HeroH2 color="white" text="Cutting-edge rota software for leisure businesses" />

              <H4 uistyle="white">
                Take the hassle out of employee scheduling with our rota software for the leisure industry. Developed for gyms, museums, golf clubs and everything in between.
                <br />
                <br />
                Our rota software enables leisure businesses of all shapes and sizes to effortlessly plan rotas, manage wage costs and employee time off.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

    <ClientLogoBar
      logos={[
        ClientLogos.CAMDEN_TOWN_BREWERY,
        ClientLogos.MANORVIEW_HOTELS,
        ClientLogos.NAMCO,
        ClientLogos.NEW_FOREST_HOTELS,
        ClientLogos.SUPERBOWL,
        ClientLogos.WARNER_LEISURE_HOTELS,
        ClientLogos.TOPGOLF,
        ClientLogos.MOLLIES,
        ClientLogos.NQ64,
      ]}
    />

    <FeatureRow
      mirrorArrange
      title="Perfect for leisure operators of all sizes"
      illustrationImage={data.venuePickerIllustration.childImageSharp.fluid}
      illustrationAltText="Switching between venues within the Rotaready app"
    >
      <Subtitle text="Your guests shouldn't be the only people having fun. Whether you're a single venue or a multi-site operator, let Rotaready's software - perfect for museums, golf clubs and gyms take the hard work out of planning your staff rotas and managing your employees." />

      <CheckmarkTextList
        items={[
          'Build employee schedules at site or department level, or manage things centrally',
          'Optimise your wage spend with powerful budgeting and real-time labour calculations',
          (
            <>
              Track <Link to="/time-attendance" style="color:inherit;">time and attendance</Link> across your business and control overtime
            </>
          ),
          (
            <>
              Approve timesheets and submit them to <Link to="/payroll" style="color:inherit;">payroll</Link> in seconds
            </>
          )
        ]}
      />
    </FeatureRow>

    <FeatureRow
      backgroundColor="brand10"
      title="Master your wage costs"
      illustrationImage={data.demandVsBookingsIllustration.childImageSharp.fluid}
      illustrationAltText="Viewing number of staff vs number of bookings within Rotaready"
    >
      <Subtitle text="Rotaready gives you a complete picture of demand, by including historical sales, the weather, bookings and our very own intelligent predictions, making forecasting a little more... leisurely." />

      <CheckmarkTextList
        columns={1}
        items={[
          'Automatic sales predictions, powered by our rota software\'s artificial intelligence',
          (
            <>
              Bring everything together in one platform with our included <Link to="/integrations" style="color:inherit;">integrations</Link>, from EPOS to bookings
            </>
          )
        ]}
      />

      <LinkWithArrow
        direction="right"
        to="/demand-forecasting"
        text="Learn about demand forecasting"
        render={props => <Link {...props} />}
      />
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="The Rotaready app comes as standard"
      illustrationImage={data.mobileScheduleIllustration.childImageSharp.fluid}
      illustrationAltText="Leisure employee view of upcoming shifts within Rotaready"
    >
      <Subtitle text="Your employees deliver a great experience for your guests. Now give them a great experience of their own. The Rotaready mobile app, allows employees to see their schedule, trade shifts, book time off and more." />

      <CheckmarkTextList
        items={[
          'Real-time shift update notifications & staff rota changes',
          'Book holiday, view this year\'s allowance or accrued hours',
          'Swap shifts amongst colleagues',
          'Clock in and out from within the app, using secure GPS',
          'Pick up extra hours with Shift Broadcasts',
          'Increase staff engagement and drive retention',
        ]}
      />
    </FeatureRow>

    <FeatureRow
      backgroundColor="brand10"
      title="Spend more time with your guests"
      illustrationImage={data.automatedSchedulingIllustration.childImageSharp.fluid}
      illustrationAltText="Automatically allocating shifts to employees within Rotaready"
    >
      <Subtitle text="Rotaready's employee scheduling software is packed full of time-saving features. It'll even build your rota automatically for you, taking into account everything from employee skills to holidays and time off. And with our beautiful drag-and-drop editor, planning shifts across multiple areas is effortless." />

      <LinkWithArrow
        direction="right"
        to="/rota-scheduling"
        text="Find out more about employee scheduling"
        render={props => <Link {...props} />}
      />
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Play by all the rules"
      illustrationImage={data.rotaValidationIllustration.childImageSharp.fluid}
      illustrationAltText="Staff scheduling validation issues highlighted within Rotaready"
    >
      <Subtitle text="As a leisure operator, compliance affects every aspect of your business. With our cloud-based rota software for leisure staff, you can enjoy full compliance with all the relevant legislation straight out of the box." />

      <CheckmarkTextList
        items={[
          'Compliance with every aspect of the Working Time regulations',
          'Fully compliant with GDPR and other Data Protection legislation',
          'Live rota validation, verifying skills, contractual hours and more',
          'Secure staff document storage with alerts before they expire',
        ]}
      />
    </FeatureRow>

    <CaseStudyCarousel />

    <RelativeWrapper backgroundColor="white">
      <FeatureListBreaker 
        displayCopy rows={2} 
        headingText="More Rotaready features, perfect for leisure businesses "
        customSubCopy={{
          ROTA_SCHEDULING: "Rota software for leisure staff that's so intelligent it can build your staff rota for you. Our beautiful drag-and-drop rota editor is also packed full of tools to help you optimise your shifts and control wage spend.",
          HR: "Employee scheduling goes hand in hand with staffing, so we built everything you need to manage your employees, from time off and holiday accrual to document storage and pay records.",
          DEMAND_FORECASTING: "Using past data to automatically predict sales, demand and other key factors, we help with gym and museum employee scheduling to ensure you have the perfect number of staff to meet demand.",
          INTEGRATIONS: "Our leisure employee scheduling software integrates with a whole host of key tools, from EPOS to reservations and payroll systems; meaning you can handpick the best solutions for every area of your business."
        }}
        />
    </RelativeWrapper>

    <GetStarted headingText="Ready to take employee scheduling to the next level for your leisure business?" />
  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "misc/hero-leisure-desktop.png" }) {
      childImageSharp {
        fixed(height: 1580, width: 2438, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "misc/hero-leisure-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 30) {
          src
        }
      }
    }

    venuePickerIllustration: file(relativePath: { eq: "illustrations/venue-picker.png" }) {
      childImageSharp {
        fluid(maxWidth: 442, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    automatedSchedulingIllustration: file(relativePath: { eq: "illustrations/automated-scheduling.png" }) {
      childImageSharp {
        fluid(maxWidth: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    mobileScheduleIllustration: file(relativePath: { eq: "illustrations/mobile-schedule.png" }) {
      childImageSharp {
        fluid(maxWidth: 489, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    demandVsBookingsIllustration: file(relativePath: { eq: "illustrations/demand-vs-bookings.png" }) {
      childImageSharp {
        fluid(maxWidth: 519, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    rotaValidationIllustration: file(relativePath: { eq: "illustrations/rota-validation.png" }) {
      childImageSharp {
        fluid(maxWidth: 465, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
